<template>
  <Card>
    <template #title>
      <div class="grid gap-4 gap-y-2 text-sm lg:grid-cols-3 grid-cols-1">
        <div class="flex md:col-span-6">
          <img class="w-6 img-publications" src="@/assets/images/icons/publications-img-index.png" />
          <span class="text-base font-bold" style="color: #1C3FAA;font-size: 30px;">Publicaciones</span>
        </div>
      </div>
      <div class="flex mt-2 flex-col md:flex-row justify-end gap-4">
        <router-link v-if="$can('pharmasan.general.publicaciones.access')" :to="{
          name: 'pharmasan.general.publicaciones.administrar',
        }">
          <Button type="button" icon="pi pi-align-justify text-sm" label="Administrar publicaciones"
            class="p-button-outlined text-sm font-medium w-full" style="height: 42px;border-radius: 10px;" />
        </router-link>

        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText @blur="onFilter" v-model="filter.search" type="text" placeholder="Search" class="border-gray-500"
            style="background: #F1F5F9; border-color: #EAEEF2; color: #686767; box-shadow: inset -2px 1px 1px rgb(75 75 75 / 18%) !important;" />
        </span>
      </div>
      <hr class="solid" style="margin-top: 15px;">
    </template>
    <template #content>
      <section>
        <div class="flex justify-end">
          <Dropdown v-model="filter.tipoPublicacionId" :options="filterCategory" optionLabel="nombre" option-value="id"
            @change="onFilter" placeholder="Ordenar Por" class="p-inputtext-sm select-outlined" style="width: 15%;" />
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
          <div v-for="(val, key) in pdfs" :key="key" style="width: 310px;">
            <div class="m-3 box cursor-pointer zoom-in" style="text-align: -webkit-center;">
              <!-- <template #header> -->
              <div>
                <iframe alt="user header" :src="val.path" class="img-file" />
              </div>
              <!-- </template> -->
              <!-- <template #title> -->
              <!-- </template> -->
              <!-- <template #content> -->
              <div style="margin-top: 15px;">
                <span class="title-index-modal" style="display:flex;padding: 15px;"> {{val.titulo}}</span>
                <span class="sub-title-index-modal"
                  style="display:flex;margin-left: 15px;">{{val.fechaPublicacion}}</span>
              </div>
              <!-- </template> -->
              <!-- <template #footer> -->
              <div class="flex justify-end">
                <button @click.stop="showPublication(val.path)" class="p-button-xs view-icon-index"
                  style="padding-right: 30px;padding-bottom: 30px;"></button>
              </div>
              <!-- </template> -->
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <Paginator @page="onFilter" v-model:first="filter.offset" v-model:rows="filter.limit"
            :totalRecords="filter.total" :rowsPerPageOptions="[10,20,30]" />
        </div>
      </section>
    </template>
  </Card>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import { helper } from '../../../../../utils/helper'
import Dropdown from 'primevue/dropdown'
import publicacionService from '../services/getPublicacionesService'
import tipoPublicacionesService from '../services/getTipoPublicacionesService'
import readFileService from '../services/readFileService'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  components: {
    Dropdown
  },

  setup (props, { emits }) {
    const toast = useToast()
    const pdfs = ref([])
    const category = ref('')
    const route = useRoute()
    const router = useRouter()
    const filter = ref({
      search: '',
      tipoPublicacionId: 0,
      offset: 0,
      limit: 10,
      total: 0
    })

    const filterCategory = ref([])

    const showPublication = (file) => {
      if (!file) {
        toast.add({ severity: 'warn', summary: 'Documento no encontrado', life: 3000 })
        return false
      }
      window.open(file, '_blank')
    }

    // watch(widthRespon.value, () => {
    //   console.log("entra watch")
    //   if(window.innerWidth == 390){
    //     widthRespon.value = 'grid gap-4 gap-y-2 text-sm grid-cols-2 lg:grid-cols-12'
    //   }else{
    //     widthRespon.value = 'grid gap-4 grid grid-cols-4 gap-4 mt-4 gap-y-2 text-sm grid-cols-2 lg:grid-cols-12'
    //   }
    // })

    const onFilter = () => {
      const queryParams = route.query
      router.push({ query: { ...queryParams, ...filter.value } })
    }

    const getTipoPublicaciones = () => {
      tipoPublicacionesService().then(({ data }) => {
        filterCategory.value = data
        filterCategory.value.unshift({ id: 0, nombre: 'Todos' })
      })
    }

    const onLoad = () => {
      const queryParams = { ...route.query }
      queryParams.offset = queryParams.offset ? queryParams.offset : 0
      queryParams.limit = queryParams.limit ? queryParams.limit : 10
      queryParams.deletedAt = true

      publicacionService(queryParams).then(({ data }) => {
        filter.value.total = data.count
        pdfs.value = []
        if (data.count) {
          data.rows.map(async e => {
            let path = ''
            if (e.path) {
              path = await readFileService(e.path)
            }
            pdfs.value.push({ ...e, path })
          })
        }
      })
    }

    const fetchQueryParams = () => {
      for (const [key] of Object.entries(filter.value)) {
        const val = route.query[key]
        if (val) {
          filter.value[key] = key !== 'search' ? parseInt(val) : val
        }
      }
    }

    onMounted(() => {
      fetchQueryParams()
      getTipoPublicaciones()
      onLoad()
    })

    watch(() => route.query, () => {
      onLoad()
    })

    return {
      helper,
      pdfs,
      filterCategory,
      filter,
      showPublication,
      category,
      onFilter
    }
  }
})

</script>
<style lang="scss" scoped>

.table_cards{
  text-align: start;
  font-size: 0.8em;
  margin-top: 10px;
}
.btn-atras{
  padding-top: 4px;
  padding-bottom: 4px;
}
.color-azul{
  color: #1c3faa;
}
.btn-change{
  position: absolute;
  right: 5px;
  top: 5px;
}
.img-file{
  height: 236px;
  width: 283px;
  object-fit: cover;
  box-shadow: 4px -9px 57px #262D6014;
  border-radius: 6px 6px 0px 0px;
}
.footerpublic {
  display: flex;
  width: 5%;
}
.p-divider-dashed.p-divider-horizontal:before {
    border-top-style: dashed;
}
.p-button-outlined.text-sm.font-medium {
  color: #6E6E6E;
}
.search-color-input {
  background: #F1F5F9;
  box-shadow: inset -2px 1px 1px #4b4b4b2e !important;
  border-color: #F1F5F9;
}
@media(max-width: 600px){
  .card-content{
    zoom: 0.6;
  }
}

@media(min-width: 390px){
  .witdhResponsive{
    width: 390px;
    columns: 1;
  }
}
</style>
